
enum modelTypes {
    system = "/system",
    demoProvider = "/demoProvider",
    toolsProvider = "/toolsProvider",
    financeProvider = "/financeProvider"
}
const ip_local='192.168.121.1';//本机 127.0.0.1 192.168.24.121
const ip_test='shinerayit.suxiangkj.com';//测试 192.168.7.131
const ip_product='tools.suxiangkj.com';//生产 192.168.7.131（内部服务器） 47.108.176.203 或 tools.suxiangkj.com(阿里云)，不要加http，因为下方要加

// let _type='local';
// let _type='test';
let _type='product';

const title='xxx管理系统';

let _isCloud:boolean=false;//是否是微服务模式

const bootParams={//不是微服务参数
    applications:['toolsProvider'],//后台服务的application.name
    aliCloudPre:'toolsProvider',//阿里云存储中的文件夹名称
    contextPath:'/toolsProvider',//访问上下文
    localPort:7789//本地开发的时候，请求端口
}


const config={
    type:_type,
    isCloud:_isCloud,//是否微服务模式
    flag1:false,//是否开启部分功能
    //系统模块集合，实际上是所有模块的context-path集合。两个用处：
    //1-建立websocket连接的时候。2-如果是微服务，在消费者（jf-consumer）访问具体业务模块的时候用到
    //如果不是微服务模式，可以把applications、aliCloudPre、contextPath配置为一样的名称
    applications:bootParams.applications,
    //附件上传到阿里云的文件夹名称
    aliCloudPre:bootParams.aliCloudPre,
    //系统访问上下文，如果不是微服务模式，需要和后台项目的server.servlet.context-path配置保持一致;
    //如果是微服务模式，需要和网关配置的路由结合【spring.cloud.gateway.routes[0].predicates[0]=Path=/projectName/**】
    contextPath:bootParams.contextPath,
    title:title,
    baseURL:_type=='local'?('http://'+ip_local+':'+(bootParams.localPort))
        :(
            _type=='test'?
                ('https://'+ip_test)//测试
                :
                ('https://'+ip_product)//生产
        ),
    modelTypes:modelTypes,
    //在刷新或者访问的时候，不需要作验证，直接放行的路由，没有的话，设置为空数组[]
    clientPathArr:['/clientUseManual','/clientCx','/clientUseManualList','/clientRepairManual','/clientViewFile','/clientServiceStationTxl','/clientSwm','/clientJx','/clientLogin','/clientUpdateInfo','/clientPersonCenter','/clientRepairChoose','/clientRepairVideos','/videoPlay'],
    //为app实例绑定全局属性（main和dialog处创建出实例之后会调用）
    bindAppProps:(app:any,props:any)=>{
        props.forEach((item:any)=>{
            app.config.globalProperties[item.key] = item.value;
        });
    }
}
export default config;